import React from "react"
import Img from "gatsby-image"
import { align, justify } from "../utilities"
import {
  Maybe,
  PrismicArticleBodyGallery,
  PrismicArticleBodyGalleryItemType,
} from "../../graphql-types"

const GalleryComponent = ({
  className,
  data,
  index,
  singular,
}: {
  className?: string
  data: PrismicArticleBodyGallery
  index: number
  singular: boolean
}) => {
  const hasCaption = data.items?.map((item) =>
    item?.image?.fluid && item?.text?.html && item?.justify
      ? item?.justify
      : false
  )

  return (
    <div className={className}>
      <div className="gallery w-full flex justify-center flex-wrap">
        {data.items?.map((item, i, a) => {
          let contents = [],
            itemImageFluid = item?.image?.fluid

          if (itemImageFluid) {
            itemImageFluid.sizes = `(min-width: 58rem) ${100 / a.length}vw, ${
              100 / Math.min(a.length, 2)
            }vw`

            contents.push(
              <Img
                alt={item?.image?.alt ?? ``}
                key={`${data.id}-img`}
                className="w-full"
                fluid={itemImageFluid}
              />
            )
          }

          if (item?.text?.html && item?.text?.html !== `<p></p>`) {
            // Don't push a caption if it is justified to bottom, it will display below.
            if (!(hasCaption && hasCaption[i] === `Bottom`)) {
              contents.push(
                <div
                  key={`${data.id}-text`}
                  className={`rte w-full ${
                    contents.length === 0
                      ? `${align(item?.align, true)} p-4`
                      : `caption mb-1 md:mb-0 pr-2`
                  }`}
                  dangerouslySetInnerHTML={{ __html: item?.text?.html }}
                />
              )
            }
          }

          return (
            <div
              className={`${
                contents.length === 0 ? `hidden md:flex` : `flex`
              } ${
                singular ? `flex-1` : `flex-none`
              } md:flex-1 flex-col w-1/2 md:w-auto ${align(
                item?.align
              )} ${justify(item?.justify)}`}
            >
              {contents}
            </div>
          )
        })}
      </div>
      {hasCaption && (
        <div className={`flex gallery w-full flex-wrap`}>
          {hasCaption.map((justify, i) => {
            if (justify === `Bottom`) {
              const item = data?.items?.[i]
              return (
                <div className={`flex-1 flex flex-col ${align(item?.align)}`}>
                  <div
                    className="w-full rte caption pr-2"
                    dangerouslySetInnerHTML={{
                      __html: item?.text?.html as string,
                    }}
                  />
                </div>
              )
            }

            return <div className="flex-1 flex flex-col" />
          })}
        </div>
      )}
    </div>
  )
}

const MobileGalleries = ({
  data,
  index,
  singular,
}: {
  data: PrismicArticleBodyGallery
  index: number
  singular: boolean
}) => {
  const itemsWithContent = data.items?.filter(
      (item) =>
        item?.image?.fluid ||
        (item?.text?.html && item?.text?.html !== `<p></p>`)
    ),
    itemPairs: Maybe<PrismicArticleBodyGalleryItemType>[][] = [[]]

  itemsWithContent?.forEach((item) => {
    const currentPairIndex = itemPairs.length - 1,
      currentPair = itemPairs[currentPairIndex]

    currentPair.length < 2 ? currentPair.push(item) : itemPairs.push([item])
  })

  return (
    <div className="block md:hidden">
      {itemPairs.map((pair) => (
        <GalleryComponent
          data={Object.assign({}, data, {
            items: pair,
            primary: { spacing: false },
          })}
          index={index}
          singular={singular}
        />
      ))}
    </div>
  )
}

const Gallery = ({
  data,
  index,
}: {
  data: PrismicArticleBodyGallery
  index: number
}) => {
  const singular = data.items?.length === 1,
    spacing = data.primary?.spacing

  return (
    <div
      className={spacing ? `mt-8 md:mt-20` : index === 0 ? `md:-mt-nav` : ``}
    >
      <GalleryComponent
        className="hidden md:block"
        data={data}
        index={index}
        singular={singular}
      />
      <MobileGalleries data={data} index={index} singular={singular} />
    </div>
  )
}

export default Gallery
export { Gallery }

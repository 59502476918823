import React, { useEffect, useRef, useState } from "react"
import Img from "gatsby-image"
import { align } from "../utilities/index"
import { PrismicArticleBodyCarousel } from "../../graphql-types"
import "wicg-inert"

const Carousel = ({
  data,
  index,
}: {
  data: PrismicArticleBodyCarousel
  index: number
}) => {
  const [carouselIndex, setCarouselIndex] = useState(0),
    carouselIndexRef = useRef(carouselIndex),
    carouselInterval = useRef<NodeJS.Timeout | null>(null),
    carouselLength = data.items?.length ?? 0,
    firstItemRatio =
      data.items?.find((item) => item?.image?.fluid?.aspectRatio)?.image?.fluid
        ?.aspectRatio ?? 1,
    ratioStyle = { paddingBottom: `${100 / firstItemRatio}%` },
    hasCaptions = data.items?.some(
      (item) => item?.text?.html && item?.text?.html !== `<p></p>`
    ),
    captions = data.items?.map((item) => item?.text?.text?.length ?? 0) ?? [],
    longestCaptionIndex = captions.indexOf(Math.max(...captions)),
    longestCaption = data.items && data.items[longestCaptionIndex],
    alignment = data.primary?.align,
    spacing = data.primary?.spacing,
    width = data?.primary?.width ? `w-2col` : `w-col`

  useEffect(() => {
    carouselIndexRef.current = carouselIndex
  }, [carouselIndex])

  useEffect(() => {
    carouselInterval.current = setInterval(() => {
      const nextCarouselIndex =
        carouselIndexRef.current + 1 >= carouselLength
          ? 0
          : carouselIndexRef.current + 1
      setCarouselIndex(nextCarouselIndex)
    }, 4000)

    return () => {
      carouselInterval.current && clearInterval(carouselInterval.current)
    }
  }, [])

  return (
    <>
      <div
        className={`carousel w-2col -max-w-4 flex flex-col ${align(
          alignment
        )} ${
          spacing ? `mt-12 md:mt-20` : index === 0 ? `md:-mt-nav` : ``
        } mx-auto`}
      >
        <div className={`${width} max-w-full mx-auto xl:mx-0`}>
          <div className={`relative w-full h-0`} style={ratioStyle}>
            {data.items?.map((item, i) => {
              let itemImageFluid = item?.image?.fluid
              if (itemImageFluid)
                itemImageFluid.sizes = data?.primary?.width
                  ? `(min-width: 68rem) 66rem, calc(100vw - 2rem)`
                  : `(min-width: 33rem) 31rem, calc(100vw - 2rem)`

              return (
                <div
                  {...{
                    "aria-hidden": carouselIndex !== i,
                    inert: carouselIndex !== i,
                    key: `${data.id}-${i}`,
                    className: `absolute inset-0 w-full h-full transition-opacity duration-1000  ${
                      carouselIndex === i
                        ? `opacity-100`
                        : `opacity-0 delay-1000`
                    }`,
                  }}
                >
                  <div className="relative w-full h-0" style={ratioStyle}>
                    {itemImageFluid ? (
                      <Img
                        alt={item?.image?.alt ?? ``}
                        className="absolute inset-0 w-full h-full"
                        fluid={itemImageFluid}
                        style={ratioStyle}
                        imgStyle={{
                          height: `100%`,
                          minHeight: `100%`,
                        }}
                      />
                    ) : (
                      <div
                        className="w-full h-0 bg-gray-200"
                        style={{ paddingBottom: `100%` }}
                      />
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        {/* Render captions if any exist */}
        {hasCaptions && (
          <div className={`relative flex ${width} -max-w-4 mx-auto xl:mx-0`}>
            {/*
             * Render an invisible placeholder caption to preserve spacing
             * between slides, while visible captions are positioned absolute
             */}
            <div
              {...{
                "aria-hidden": true,
                className: `rte w-full caption invisible`,
                inert: true,
                dangerouslySetInnerHTML: {
                  __html: longestCaption?.text?.html ?? ``,
                },
              }}
            />
            {data.items?.map((item, i) => (
              <div
                {...{
                  "aria-hidden": carouselIndex !== i,
                  className: `${
                    carouselIndex === i ? `visible` : `invisible`
                  } absolute top-0 rte w-full caption`,
                  dangerouslySetInnerHTML: {
                    __html: item?.text?.html ?? `<p>&nbsp;</p>`,
                  },
                  key: `${data.id}-${i}-text`,
                }}
              />
            ))}
          </div>
        )}
      </div>
    </>
  )
}

export default Carousel
export { Carousel }

import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import useArticleContext from "../hooks/useArticleContext"
import { useTranslation } from "react-i18next"

const ArticleFooterLink = () => {
  const [
      {
        index,
        previousIndex,
        previousPathname,
        previousTitle,
        previousImage,
        previousDate,
      },
    ] = useArticleContext(),
    { t, i18n } = useTranslation()

  const previousTextString =
      index === 0
        ? t(`navigation.latest_article`)
        : t(`navigation.previous_article`),
    previousIndexString =
      typeof previousIndex !== `undefined` &&
      `${previousIndex + 1}`.padStart(3, `0`),
    // iOS doesn't like new Date(+0000)
    previousDateObject = previousDate && new Date(previousDate.split(`+`)[0]),
    previousDateString =
      previousDateObject &&
      previousDateObject.toLocaleDateString(i18n.language, {
        year:
          previousDateObject.getFullYear() === new Date().getFullYear()
            ? undefined
            : `numeric`,
        month: `long`,
        day: `numeric`,
      }),
    previousImageAlt = previousImage?.alt ?? ``

  let previousImageFluid = previousImage?.fluid
  if (previousImageFluid) previousImageFluid.sizes = `9rem`

  return (
    <div className="w-48 mx-auto py-36">
      <Link className="text-center group" to={previousPathname ?? ``}>
        <p className="font-medium text-gray-900">
          {previousTextString} {previousIndexString ?? `000`}
        </p>
        {previousImageFluid ? (
          <Img
            alt={previousImageAlt}
            className="w-36 mx-auto my-3"
            fluid={previousImageFluid}
          />
        ) : (
          <div className="w-36 h-36 mx-auto my-3 bg-gray-200" />
        )}
        <p className="text-gray-900 group-hover:text-red transition-colors duration-150">
          <span className="uppercase">
            {previousTitle ?? t(`navigation.untitled`)}
          </span>
          {` `}
          <span className="font-serif lowercase">
            {previousDateString ?? t(`navigation.undated`)}
          </span>
        </p>
      </Link>
    </div>
  )
}

export default ArticleFooterLink
export { ArticleFooterLink }

import React from "react"
import Img from "gatsby-image"
import { align, justify } from "../utilities"
import { PrismicArticleBodyColumn } from "../../graphql-types"

const Column = ({
  data,
  index,
}: {
  data: PrismicArticleBodyColumn
  index: number
}) => {
  const spacing = data.primary?.spacing,
    contentLength: number[] = [],
    hasCaption = data.primary?.width
      ? []
      : data.items
          ?.slice(0, 2)
          .map((item) =>
            item?.image?.fluid && item?.text?.html && item?.justify
              ? item?.justify
              : false
          )

  return (
    <>
      <div
        className={`column w-full flex flex-wrap flex-col xl:flex-row justify-center ${
          spacing ? `mt-8 md:mt-20` : index === 0 ? `md:-mt-nav` : ``
        }`}
      >
        {data.items?.slice(0, data.primary?.width ? 1 : 2).map((item, i) => {
          let contents = [],
            itemImageFluid = item?.image?.fluid

          if (itemImageFluid) {
            itemImageFluid.sizes = data.primary?.width
              ? `(min-width: 68rem) 66rem, calc(100vw - 2rem)`
              : `(min-width: 33rem) 31rem, calc(100vw - 2rem)`

            contents.push(
              <Img
                alt={item?.image?.alt ?? ``}
                key={`${data.id}-img`}
                className="w-full"
                fluid={itemImageFluid}
              />
            )
          }
          if (item?.text?.html && item?.text?.html !== `<p></p>`) {
            contents.push(
              <div
                key={`${data.id}-text`}
                className={`rte w-full ${
                  // This text is a caption only if contents.length is zero (no image is present)
                  contents.length === 0
                    ? `${align(item?.align, true)}`
                    : `caption`
                } ${
                  // If this column has a caption justified to bottom
                  // display it here only on small screens when columns are arranged vertically
                  // on large screens when they are arranged horizontally display this caption
                  // below both primary content areas for alignment purposes
                  hasCaption && hasCaption[i] === `Bottom`
                    ? `block xl:hidden`
                    : ``
                }`}
                dangerouslySetInnerHTML={{ __html: item?.text?.html }}
              />
            )
          }

          contentLength.push(contents.length)

          return (
            <div
              className={`flex flex-col -max-w-4 mx-auto xl:mx-0
                ${align(item?.align)} ${justify(item?.justify)}
                ${data?.primary?.width ? `w-2col` : `w-col`} ${
                // If this is the second column
                // draw left margin on large screens
                i === 1 ? `xl:ml-16` : ``
              } ${
                // If this is the second column and the both columns have content
                // pad the top on mobile only
                i === 1 && contentLength.indexOf(0) === -1 ? `mt-8 xl:mt-0` : ``
              }
              `}
            >
              {contents}
            </div>
          )
        })}
      </div>
      {hasCaption && (
        <div className="column w-full hidden xl:flex flex-col xl:flex-row justify-center">
          {hasCaption.map((justify, i) => {
            let caption

            if (justify === `Bottom`) {
              const html = data.items && data.items[i]?.text?.html
              caption = (
                <div
                  className="w-full rte caption"
                  dangerouslySetInnerHTML={{ __html: html as string }}
                />
              )
            }

            return (
              <div
                className={`flex flex-col -max-w-4 mx-auto xl:mx-0 w-col ${
                  // If this is the second column
                  // draw left margin on large screens
                  i === 1 ? `xl:ml-16` : ``
                }`}
              >
                {caption}
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}

export default Column
export { Column }

import React from "react"
import { Carousel } from "./carousel"
import { Column } from "./column"
import { Gallery } from "./gallery"
import { Video } from "./video"
import { PrismicArticleBodySlicesType } from "../../graphql-types"

const Slice = ({
  data,
  index,
}: {
  data: PrismicArticleBodySlicesType
  index: number
}) => {
  switch (data?.slice_type) {
    case "carousel":
      return <Carousel key={data.id} data={data} index={index} />
    case "column":
      return <Column key={data.id} data={data} index={index} />
    case "gallery":
      return <Gallery key={data.id} data={data} index={index} />
    case "video":
      return <Video key={data.id} data={data} index={index} />
    default:
      return null
  }
}

export default Slice
export { Slice }

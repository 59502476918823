import React from "react"
import { getI18n } from "react-i18next"
import { PrismicArticleBodyVideo } from "../../graphql-types"

const Video = ({
  data,
  index,
}: {
  data: PrismicArticleBodyVideo
  index: number
}) => {
  const { language } = getI18n(),
    width = data.primary?.width,
    spacing = data.primary?.spacing,
    video = data.primary?.video,
    ratio = video?.height && video?.width ? video?.height / video?.width : 1,
    text = data.primary?.text,
    controls = data.primary?.controls,
    closedCaptions = data.primary?.closed_captions,
    quietLoop = data.primary?.quiet_loop

  let html = video?.html,
    originalSrc,
    modifiedSrc

  if (html) {
    const srcMatch = html && html.match(/src="([^"]+)"/)

    originalSrc = Array.isArray(srcMatch) && srcMatch.length >= 1 && srcMatch[1]
    modifiedSrc = originalSrc

    if (typeof originalSrc === `string` && typeof modifiedSrc === `string`) {
      modifiedSrc = modifiedSrc.replace(`youtube.com`, `youtube-nocookie.com`)
      modifiedSrc = modifiedSrc.split(`?`)[0]
      modifiedSrc += `?hl=${language}&modestbranding=1&rel=0`

      if (!controls) modifiedSrc += `&controls=0`
      if (!closedCaptions) modifiedSrc += `&cc_load_policy=0`
      if (quietLoop) modifiedSrc += `&mute=1&loop=1&autoplay=1`
      if (quietLoop)
        modifiedSrc += `&playlist=${modifiedSrc.substring(
          modifiedSrc.lastIndexOf(`/`) + 1,
          modifiedSrc.indexOf(`?`)
        )}`

      html = html.replace(originalSrc, modifiedSrc)
    }
  }

  return (
    <>
      <div
        className={`video max-w-full ${
          width ? `w-full` : `w-2col`
        } flex flex-col items-center ${
          spacing ? `mt-12 md:mt-20` : index === 0 ? `md:-mt-nav` : ``
        } mx-auto`}
      >
        {html && (
          <div
            className={`relative w-full h-0 ${
              quietLoop ? `pointer-events-none` : ``
            }`}
            style={{ paddingBottom: `${ratio * 100}%` }}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        )}
        {text?.html && text.html !== `<p></p>` && (
          <div
            key={`${data.id}-text`}
            className={`rte w-full caption mb-1 md:mb-0 ${width ? `pr-2` : ``}`}
            dangerouslySetInnerHTML={{ __html: text.html }}
          />
        )}
      </div>
    </>
  )
}

export default Video
export { Video }

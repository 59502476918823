import React from "react"
import { Slice } from "./slice"
import { ArticleFooterLink } from "./articleFooterLink"
import { PrismicArticle } from "../../graphql-types"
import { SEO } from "./seo"
import { ArticleContextInterface } from "../context/article"

const Article = ({
  prismicArticle,
  pageContext,
}: {
  prismicArticle: PrismicArticle
  pageContext?: ArticleContextInterface
}) => {
  if (!prismicArticle?.data) return null

  return (
    <article className="article">
      <SEO data={prismicArticle} languages={pageContext?.languages} />
      {prismicArticle.data?.body?.map((slice, i) =>
        slice ? (
          <Slice
            key={slice?.id ?? `${prismicArticle.id}-${i}`}
            data={slice}
            index={i}
          />
        ) : null
      )}
      <ArticleFooterLink />
    </article>
  )
}

export default Article
export { Article }
